import ReactGA from 'react-ga';

export const init = () => {
  ReactGA.initialize('UA-65884089-1', {
    gaOptions: {
      anonymizeIp: true,
    },
  });
};

export const trackPageview = (path) => {
  ReactGA.pageview(path);
};

export const trackEvent = (args) => {
  const { action, category, label } = args;
  ReactGA.event({
    category,
    action,
    label,
  });
};

export const trackOutboundLink = (link) => {
  ReactGA.event({
    category: 'Outbound link',
    action: 'Visit',
    label: link,
  });
};
