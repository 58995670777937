import PropTypes from 'prop-types'
import React from 'react'
import styles from './Header.module.scss'
import * as analytics from '../helpers/analytics'
import { StaticQuery, graphql } from 'gatsby'

const Header = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          logo: file( relativePath: { eq: "parasailing-makarska-logo.svg" })
          {
            publicURL
          }
          menu: file( relativePath: { eq: "menu.svg" })
          {
            publicURL
          }
          site {
            siteMetadata {
              menuLinks {
                name,
                link
              }
            }
          }
        }
      `}
      render={data => (
        <header
          className={styles.headerContainer}
        >
          <div className={styles.headerContent}>
            <img
              alt="Parasailing Makarska"
              height={50}
              src={data.logo.publicURL}
            />
            <div>
              <nav className={`${styles.desktopNavigation} ${props.isMenuOpen ? styles.opened : ''}`}>
                <header>
                  <h6>
                    Menu
                  </h6>
                  <button
                    onClick={props.closeMenu}
                  >
                    &times;
                  </button>
                </header>
                {
                  data.site.siteMetadata.menuLinks.map(link => {
                    return (
                      <a
                        key={link.link}
                        data-scroll
                        href={link.link}
                        onClick={() => {
                          analytics.trackEvent({ category: 'navigation', action: 'click', label: link.name });
                          if (props.isMenuOpen) {
                            props.closeMenu();
                          }
                        }}
                      >
                        {link.name}
                      </a>
                    )
                  })
                }
              </nav>
              <button className={styles.menuButton} onClick={props.openMenu}>
                <img alt="menu icon" src={data.menu.publicURL} height="30px" />
              </button>
            </div>
          </div>
        </header>
      )}
    />
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
